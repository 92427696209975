import logo from './logo.svg';
import './App.css';
import AppRoutes from './app-routes';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple,orange,red, grey, blueGrey } from '@mui/material/colors';


const theme = createTheme({
  palette: {
    primary: {
      main: "#400905",
    },
    secondary: {
      main: red[50],
    },
    section:{
      loadingIndicator:{
        primary:{
          main: "#400905"
        }
      },
      background:{
        main: blueGrey[50],
        contrast: blueGrey[800]
      }
    },
    table:{
      primary:{
        main: "#fcf6fd",
        dark:"#fae0ff"
      },
      secondary:{
        main: "white",
        dark: grey[100]
      }
    },
    text:{
      primary:  grey[800],
      section:{
        title: {
          primary: {
              main: blueGrey[900]
          },
          secondary: {
            main: blueGrey[300]
          }
        },
        normal: {
          primary: {
            main: grey[700]
          }
        }
      }
    },
    background:{
      section:{
        main: blueGrey[50],
        contrast: blueGrey[700]
      }
    }

  },
  text:{
    section: {
      title:{
        fontFamily: "Roboto"
      },
      normal:{
        fontFamily: "Roboto"
      }
    }
  },
  status: {
    danger: orange[500],
  },
});

// Initialize Firebase Authentication and get a reference to the service




function App() {
  return (
    
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
      
    </BrowserRouter>
  );
}

export default App;
