export default class TurnosAPI {
    constructor(auth){
        this.auth = auth;
    }

    timeout(millis) {
        let prom = new Promise((resolve,reject)=>{
            setTimeout(()=>{
                resolve();
            },millis);
        });
        return prom;
    }
    
    async makeRequest(opts) {

        let url = opts.url;
        if (opts.query) {
            url += "?" + (new URLSearchParams(opts.query));
        }

        let headers = opts.headers ? {...opts.headers} : {};

        let idToken = await this.auth.currentUser.getIdToken(true);

        headers['Authorization'] = 'Bearer '+idToken;

        let response = await fetch(url, {
            method: opts.method,
            headers: headers,
            body: opts.body ? JSON.stringify(opts.body) : null
        });

        return response;
    }

    async getInvoices (queryDate) {
        console.log("el query date ",{queryDate})
        let dayAfter = new Date(queryDate.getTime());

        console.log("el query date 2 ",{dayAfter,d: queryDate.toISOString()})
        dayAfter.setDate(dayAfter.getDate() + 1);
        let opts = {
            method: "GET",
            url: "/api/alegra/invoices",
            query: {
                date_afterOrNow: queryDate.getFullYear()+"-"+(queryDate.getMonth()+1).toString().padStart(2,"0")+"-"+queryDate.getDate().toString().padStart(2,"0"),
                date_beforeOrNow: dayAfter.getFullYear()+"-"+(dayAfter.getMonth()+1).toString().padStart(2,"0")+"-"+dayAfter.getDate().toString().padStart(2,"0"),
                order_direction: "ASC",
                order_field:"id"
            }
        }

        let response = await this.makeRequest(opts);

        let resp;
        try {   
            
            if ( response.headers.get("Content-Type").indexOf("application/json") !== -1 ) {
                resp = await response.json();
            }else{
                resp = await response.text();
            }
           


        } catch (err) {
            throw err;
            //resp = await response.text();

        }

        if (response.ok) {

            return resp;

        } else {

            throw resp;
        }

    }

    getSaleConditionColor(saleCondition){
        let color = "";
        switch(saleCondition){
            case "CASH": color="#8bc34a"; break;
            case "DEBIT-CARD": color="#ffd54f"; break;
            case "TRANSFER": color="#ff5722"; break;
            case "CREDIT-CARD": color="#e91e63"; break;
            default: color="#78909c"
        }
       return color;
    }

    async calculateTotalsTurno(invoices) {

        let result = {
            totalTurno: 0,
            totalCAETurno:0,
            totalsBySaleCondition:{}
        }

        for( let i=0; i < invoices.length; i++){
            let paymentMethod = invoices[i].payments[0].paymentMethod.toUpperCase();
            result.totalTurno += invoices[i].total;
            if(!result.totalsBySaleCondition[paymentMethod]){
                result.totalsBySaleCondition[paymentMethod] = 0;
            }

            result.totalsBySaleCondition[paymentMethod] += invoices[i].total;
            if(invoices[i].stamp){
                result.totalCAETurno += invoices[i].total;
            }
            
        }

        result.cantInvoicesTurno = invoices.length;

        let arr = [];

        console.log(result);
        if( invoices.length > 0){
            arr.push({
                label:"CAE",
                includeInBar: false,
                color: "#37474f",
                type:"currency",
                value: result.totalCAETurno
            });
        }

        Object.keys(result.totalsBySaleCondition).sort((a,b) => {
            if (a > b) {
                return 1;
            }
            if (b > a) {
                return -1;
            }
            return 0;
        }).forEach( k => {
            let obj = {
                label: k,
                includeInBar: true,
                type:"currency",
                color: this.getSaleConditionColor(k),
                value: result.totalsBySaleCondition[k]
            }
            arr.push(obj);
        });


        result.totalsBySaleCondition = arr;

        return result;

    }




}