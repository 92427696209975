import { initializeApp } from "firebase/app";

import { getAuth,connectAuthEmulator } from "firebase/auth";
import { getFirestore,connectFirestoreEmulator } from "firebase/firestore";

const {
  REACT_APP_FIREBASE_APIKEY,
  REACT_APP_FIREBASE_AUTHDOMAIN,
  REACT_APP_FIREBASE_PROJECTID,
  REACT_APP_FIREBASE_STORAGEBUCKET,
  REACT_APP_FIREBASE_MESSAGINGSENDERID,
  REACT_APP_FIREBASE_APPID,
  REACT_APP_ENV,
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_APIKEY,
  authDomain: REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECTID,
  storageBucket: REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: REACT_APP_FIREBASE_APPID,
};

const app = initializeApp(firebaseConfig);



export const auth = getAuth(app);
export const db = getFirestore(app);

if(window.location.hostname === "localhost") {
  connectAuthEmulator(auth,"http://localhost:9099");
  connectFirestoreEmulator(db,"localhost","8080");
}


export default app;