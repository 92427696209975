
import { useEffect,useState } from 'react';

import { Box, TextField } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import "moment/locale/es";

moment.locale("es");


function CustomDatePicker(props) {

    const theme = useTheme();
    
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
            <DatePicker

                label={props.label}
                value={props.date}
                onChange={(newValue) => {
                 
                    let newDate = newValue.toDate();
                    props.onChange(newDate);
                }}
                renderInput={(params) => {
                    params.sx = props.sx;
                    params.disabled = props.disabled;
                    return <TextField {...params} />
                }}
            />
        </LocalizationProvider>
    );
}

export default CustomDatePicker;