
import { Box, CircularProgress,Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme,createTheme,ThemeProvider } from '@mui/material/styles';




function LoadingIndicator(props) {

    const progressTheme = createTheme({
        palette: {
          primary: {
            main: props.progressColor || grey[700],
          }
        }
      });

    const theme = useTheme();
    
    return (
        <Box sx={{display: "flex", width:"100%",  flexDirection:"column", flexGrow:"1", alignItems:"center", justifyContent:"center",...props.sx}}>
            <ThemeProvider theme={progressTheme}>
                <CircularProgress color="primary" />
            </ThemeProvider>
            
            <Typography
                variant="p"
                noWrap
                sx={{
                    mt: 2,
                    display: { xs: "flex" },
                    flexGrow: { xs: 0 },
                    justifyContent: { xs: "center" },
                    fontFamily: theme.text.section.normal.fontFamily,
                    fontWeight: 500,
                    letterSpacing: '.3rem',
                    color: theme.palette.text.section.normal.primary.main,
                    textDecoration: 'none',
                }}
            >
                {props.text}
            </Typography>
        </Box>
    );
}

export default LoadingIndicator;



