
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Divider, List, ListItem } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PiacePiuLogo from './piacepiu-logo';
import { grey, red, purple, blueGrey } from '@mui/material/colors';
import { Icon, SwipeableDrawer, ListItemButton, ListItemIcon, ListItemText,Card } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import { useNavigate } from 'react-router-dom';
import { bgcolor } from '@mui/system';

const settings = ['Logout'];

const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

const drawerWidth = 240;

const Scaffold = ({ ...props }) => {

    const theme = useTheme();
    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [selectedOption,setSelectedOption] = React.useState("");

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleUserMenu = (opt) => {
        switch(opt) {
            case "logout": 
                props.onLogOut();
            break;
            default: console.log("option selected "+opt);
        }
        setAnchorElUser(null);
    };

    const handleListItemClick = (evt, name) => {
       
        setSelectedOption(name);
        if(mobileOpen){
            handleDrawerToggle();
        }
        navigate("/dashboard/"+name);
    }

    const container = window !== undefined ? () => window().document.body : undefined;

    const optionsList = <List component="nav">
        <ListItemButton sx={{m:"0.5em"}} selected={selectedOption === "control-turnos"} onClick={(event) => handleListItemClick(event, "control-turnos")}>
            <ListItemIcon>
                <RecentActorsIcon />
            </ListItemIcon>
            <ListItemText primary="Control Turnos" />
        </ListItemButton>


    </List>;


    return (
        <Box sx={{ display: 'flex', height:"100%" }}>
            <AppBar
                position="fixed"
                sx={{
                    display: { xs: "block", lg: "none" },
                    bgcolor: { xs: theme.palette.primary.main, lg: theme.palette.secondary.main },
                    width: { lg: `calc(100% - ${drawerWidth}px)` },
                    ml: { lg: `${drawerWidth}px` },
                }}
            >
                <Toolbar >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { lg: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: { xs: 1, lg: 0 }, justifyContent: { xs: "flex-start"}, color: grey[100], fontSize: 40, display: { xs: 'flex', lg: 'none' }, mr: 1 }}>
                        <PiacePiuLogo sx={{ color: grey[100], fontSize: 40, display: { xs: 'flex' }, mr: 1 }} />
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: "flex" },
                                flexGrow: { xs: 0 },
                                justifyContent: { xs: "center" },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            PiacePiu
                        </Typography>
                    </Box>
                    <Box sx={{ flexGrow: { lg: 1 }, display: { xs: "none", lg: "flex" } }}>

                    </Box>
                    <Box sx={{ flexGrow: 0, display: { xs: "flex", lg: "none" }, alignItems:"center" }}>
                        <Typography sx={{ display:{xs:"none",sm:"block"}, marginRight: "0.2em", fontWeight:"500", overflow:"hidden", textOverflow:"ellipsis"}}>{props.currentUser && ( props.currentUser.displayName ||  props.currentUser.email)}</Typography>
                        
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                {
                                    props.currentUser && props.currentUser.photoURL ?
                                        (<Avatar alt="Profile Pic" referrerPolicy="no-referrer" src={props.currentUser.photoURL} />)
                                        :
                                        (<Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                                            <AccountCircleIcon sx={{ color: purple[50] }} />
                                        </Avatar>)

                                }

                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem key="username" sx={{display:{xs:"flex",lg:"none"}}}>
                                {
                                props.currentUser && props.currentUser.photoURL ?
                                    (<Avatar alt="Profile Pic" referrerPolicy="no-referrer" sx={{mr: 1}} src={props.currentUser.photoURL} />)
                                    :
                                    (<Avatar sx={{ bgcolor: theme.palette.primary.main,mr: 1 }}>
                                        <AccountCircleIcon sx={{ color: purple[50] }} />
                                    </Avatar>)

                                }
                                <Typography sx={{  
                                    marginRight: "0.2em", fontWeight:"500", overflow:"hidden", 
                                    textOverflow:"ellipsis"}}>
                                        {props.currentUser && ( props.currentUser.displayName ||  props.currentUser.email)}
                                        
                                </Typography> 
                            </MenuItem>
                            <Divider sx={{display:{xs:"block",lg:"none"}}} />
                            <MenuItem key="logout" onClick={evt => handleUserMenu("logout")}>
                                <Typography textAlign="center">LogOut</Typography>
                            </MenuItem>

                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>

            <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { sm: 0 } }}>
                <SwipeableDrawer
                    disableBackdropTransition={!iOS} disableDiscovery={iOS}
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onOpen={handleDrawerToggle}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >



                    {optionsList}
                </SwipeableDrawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', lg: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    <Toolbar sx={{ bgcolor: theme.palette.primary.main }}>
                        <Box sx={{ flexGrow: { xs: 0, sm: 1 }, color: grey[100], fontSize: 40, display: { xs: 'none', lg: 'flex' }, mr: 1 }}>
                            <PiacePiuLogo sx={{ color: grey[100], fontSize: 40, display: { xs: 'flex' }, mr: 1 }} />
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    mr: 2,
                                    display: { xs: "flex" },
                                    flexGrow: { xs: 0 },
                                    justifyContent: { xs: "center" },
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                PiacePiu
                            </Typography>
                        </Box>
                    </Toolbar>

                    {optionsList}
                </Drawer>



            </Box>
            <Box
                component="main"
                sx={{ display:"flex", flexDirection:"column", p: {xs:"4em 0.5em 0.5em 0.5em",sm:"4.5em 0.5em 0.5em 0.5em", lg:1}, width: { xs: "100%", lg: `calc(100% - ${drawerWidth}px)` }, bgcolor: theme.palette.background.section.main }}
            >
                <Box sx={{display: "flex",flexGrow:1}}>
                    <Box sx={{ flexGrow: 1, display: "flex" }}>

                    </Box>
                    <Box sx={{  display: { xs: "none", lg: "flex" }, flexDirection:"row",
                        justifyContent:"flex-end", 
                        minWidth:"10em",
                        m:"0.5em 0 0.5em 0",p:"0 1em 0 1em",borderRadius:"2em", bgcolor: "white" 
                        ,boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"}}>
                        
                        <p style={{ marginRight: "0.5em", fontWeight:"500",overflow:"hidden", textOverflow:"ellipsis" }}>
                            {props.currentUser && ( props.currentUser.displayName ||  props.currentUser.email)}
                        </p>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                {
                                    props.currentUser && props.currentUser.photoURL ?
                                        (<Avatar sx={{m:"0.3em 0 0.3em 0"}} alt="Profile Pic" referrerPolicy="no-referrer" src={props.currentUser.photoURL} />)
                                        :
                                        (<Avatar sx={{ bgcolor: theme.palette.section.background.contrast, m:"0.3em 0 0.3em 0"}}>
                                            <AccountCircleIcon sx={{ color: purple[50] }} />
                                        </Avatar>)

                                }

                            </IconButton>
                        </Tooltip>
                       
                    </Box>
                </Box>
                <Card sx={{display:"flex",flexGrow:"2",  height:"100%"}}>
                    {props.children}
                </Card>
                
            </Box>
        </Box>


    )

}

export default Scaffold;