
import { Box, CircularProgress,Typography,Checkbox } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme,createTheme,ThemeProvider } from '@mui/material/styles';

const getFormattedString = value => {
    const userLocale =
        navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.language;

    let moneyString = new Intl.NumberFormat(userLocale);

    return moneyString.format(value);
}

function TurnosReportItem(props) {
   
    const theme = useTheme();

    var bgColor = props.highlight ? theme.palette.table.primary.main: theme.palette.table.secondary.main;
    var bgColorAccent = props.highlight ? theme.palette.table.primary.dark: theme.palette.table.secondary.dark;
    
    if(props.data.stamp){
        bgColor = props.bgColor;
        bgColorAccent = props.bgColorAccent;
    }


    
    return (
        <Box sx={{display: "flex", width:"100%",height:"90%",p:"0 0.5em 0 0.5em", flexDirection:"column", alignItems:"center", justifyContent:"center",...props.sx}}>
            <Box sx={{display: "flex", width:"100%",bgcolor:bgColor, flexDirection:"row", alignContent:"center", justifyContent:"flex-start"}}>
                <p style={{margin: "0.5em 0 0 0.5em",p:"0.5em",fontSize:"0.9em"}}># {props.data.numberTemplate.fullNumber}</p>
            </Box>
            <Box sx={{display: "flex", width:"100%",p:"0.5em",borderRadius:"0.2em", bgcolor:bgColor, flexDirection:"row", alignContent:"center", justifyContent:"space-evenly", flexGrow:"1"}}>
                <Box  sx={{display: "flex", flexDirection:{xs:"column",md:"row"}, alignContent:"center", justifyContent:"space-evenly", flexGrow:"1"}}>
                    <div style={{height:"100%",display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <p style={{fontWeight:"bold", width:"6em",textAlign:"center"}}>{props.data.payments[0].paymentMethod.toUpperCase()}</p>
                    </div>

                    <div style={{height:"100%",display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <p style={{fontWeight:"bold",textAlign:"center"}}>{props.data.datetime}</p>
                    </div>
                </Box>

                <div style={{display:"flex",height:"100%", width:"12em", overflow:"scroll", alignItems:"flex-start", flexDirection:"column", justifyContent:"center"}}>
                    <ul style={{margin:"0.2em 0 0.2em 0"}}>
                        {
                            props.data.items.map( (it,ix) => {
                                return <li key={ix}><p style={{fontWeight:"400",flexGrow:"1",  margin:"0.2em 0 0.2em 0"}}>{it.quantity+" x "+it.name}</p></li>
                            })
                        }
                    </ul>
                </div>
                
                <div style={{height:"100%",display:"flex",width:"4em", alignItems:"center", justifyContent:"center"}}>
                    <Checkbox checked={props.checked} onChange={evt => props.onItemChecked && props.onItemChecked(props.data)} />
                </div>
                

            </Box>
            <Box sx={{display: "flex", width:"100%",p:"0 0.5em 0 0.5em",borderRadius:"0.2em",bgcolor:bgColorAccent, flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                <p style={{fontWeight:"bold",margin:"0.2em 0 0.2em 0"}}>Total: ${getFormattedString(props.data.total)}</p>
            </Box>

        </Box>
    );

}

export default TurnosReportItem;