
import { Box, CircularProgress, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect } from "react";
import useGenerateRandomColor from "./use-generate-random-color";

function PaymentMethodChart(props) {

    const theme = useTheme();
    const { generateColor } = useGenerateRandomColor();

    const getFormattedString = value => {
        const userLocale =
            navigator.languages && navigator.languages.length
                ? navigator.languages[0]
                : navigator.language;

        let moneyString = new Intl.NumberFormat(userLocale);

        return moneyString.format(value);
    }

    const getSymbol = type => {
        switch (type) {
            case "currency": return "$" ; break;
            default: return "";
        }
    }

    return (

        <Box sx={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", ...props.sx }}>
            <Box sx={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)", zIndex: "1", borderRadius: "0.5em", overflow: "hidden" }}>
                {
                    props.data && props.data.items.filter( it => {
                        return it.includeInBar;
                    }).map(it => {
                        let prePorc = ((it.value / props.data.total) * 100);
                        let porc = (prePorc % (Math.floor(prePorc))) >= 0.5 ? Math.ceil(prePorc) + "%" : Math.floor(prePorc) + "%";
                        let color = it.color || "#" + generateColor();
                        it.color = color;

                        return <Box key={it.label} sx={{ height: "2em", display: "flex", width: porc, flexDirection: "row", alignItems: "center", justifyContent: "flex-start", bgcolor: color }}>

                        </Box>
                    })
                }
            </Box>
            <Box sx={{ display: "flex", width: "100%", flexDirection: { xs: "column", md: "row" }, alignItems: { xs: "flex-start", lg: "center" }, justifyContent: "flex-start", mt: 1, p: 1 }}>
                {
                    props.data && props.data.items.map(it => {
                        let prePorc = ((it.value / ( it.total || props.data.total )) * 100);
                        let porc = (prePorc % (Math.floor(prePorc))) >= 0.5 ? Math.ceil(prePorc) + "%" : Math.floor(prePorc) + "%";
                        let color = it.color;

                        return <Box key={it.label} sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                            <Box sx={{ width: 20, height: 10, borderRadius: "0.5em", bgcolor: color, ml: 1 }}></Box>
                            <p style={{ color: theme.palette.text.primary.main, margin: "0 0.5em 0 0.5em", fontWeight: "bold" }}>{it.label}</p>
                            <p style={{ margin: "0 0.5em 0 0" }}>{getSymbol(it.type)}{getFormattedString(it.value)}</p>
                            <p>{"(" + porc + ")"}</p>
                        </Box>
                    })
                }
            </Box>
        </Box>

    );

}

export default PaymentMethodChart;