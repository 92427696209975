
import { useEffect, useState } from 'react';

import { FormControl,InputLabel,Select,MenuItem } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import moment from "moment";
import "moment/locale/es";

moment.locale("es");


function ItemSelector(props) {

    const theme = useTheme();

    const [selectedItem,setSelectedItem] = useState(0);

    useEffect(()=>{
        if(props.items){
            
            setSelectedItem(selectedItem);
            props.onSelected && props.onSelected(selectedItem);
        }
        

    },[props.items]);

    const handleChange = evt => {
        setSelectedItem(evt.target.value);
        props.onSelected && props.onSelected(evt.target.value);
    }

    return (
        <FormControl disabled={props.disabled} variant="filled" sx={{ minWidth: 120,...props.sx }}>
            <InputLabel id="label-id">{props.label}</InputLabel>
            <Select
                labelId="label-id"
                id="select"
                value={selectedItem}
                onChange={handleChange}
            >
                {
                   
                    props.items && props.items.map( (item,ix) => {
                       
                        return <MenuItem key={ix} value={ix} style={{whiteSpace: 'normal'}}>{item}</MenuItem>
                    })
                }
               
            </Select>
        </FormControl>
    );
}

export default ItemSelector;