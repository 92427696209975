
import { Box, CircularProgress, Typography } from "@mui/material";
import { grey,lightGreen } from "@mui/material/colors";
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import TurnosReportItem from "./turnos-report-item";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";



function renderRow(props) {
    const { data,index, style } = props;
    const highlight = index % 2 === 0;
    const checked = data.checkedInvoices[data.invoices[index].id] !== undefined;
    return (
        <div style={{...style, display:"flex", alignItems:"center",justifyContent:"center"}}>
            <TurnosReportItem data={data.invoices[index]} highlight={highlight} 
            bgColor={lightGreen[50]} bgColorAccent={lightGreen[200]}
            checked={checked} onItemChecked={data.onItemChecked}/>
        </div>
        
    );
}

function TurnosReportTable(props) {

    const theme = useTheme();
    
    return (
        <Box sx={{ display: "flex", width: "100%",position:"relative" , ...props.sx }}>
            <AutoSizer>
                {({ height, width }) => (
                    <List
                        className="List"
                        itemData={{
                            onItemChecked: props.onItemChecked,
                            invoices: props.invoices,
                            checkedInvoices: props.checkedInvoices
                        }}
                        height={height}
                        itemCount={props.invoices.length}
                        itemSize={220}
                        width={width}
                    >
                        {renderRow}
                    </List>
                )}
            </AutoSizer>

        </Box>
    );
}

export default TurnosReportTable;



