import React, { useEffect,useState } from "react";

import {auth } from "./firebase";

import { onAuthStateChanged} from "firebase/auth";

import {
    Navigate,
    useNavigate,
  } from 'react-router-dom';

auth.useDeviceLanguage();

 const AuthContext = React.createContext();

export const AuthProvider = ({children}) => {

    //const navigate = useNavigate();

    const [loading,setLoading] = useState(true);
     
    const [loginState,setLoginState] = useState({
        currentUser: null,
        loading: true,
        userInfo:null
    });


    useEffect(() => {
           
            let listener = onAuthStateChanged(auth, (currUser)=>{
                console.log("cambia state de auth ",{currUser});
                if(currUser){
    
                    setLoginState({
                        currentUser:currUser,
                        loading:false,
                        userInfo: currUser
                    }); 
    
                   
                }else{
                    
                    setLoginState({
                        currentUser:currUser,
                        loading:false,
                        userInfo: null
                    });
                    
                }
                
                
            });

        return () => {
            listener();
            
        }
        
    },[]);

   

    return (
        <AuthContext.Provider value = {{loginState}} >
            
            {children}

        </AuthContext.Provider>
    );


}

export const signOut =  async () => {
    await auth.signOut();
}

export const useAuth = () => {
    return React.useContext(AuthContext);
};

