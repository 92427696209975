
import { CircularProgress, Box, Typography } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useAuth, signOut } from '../auth-provider';
import Scaffold from '../scaffold';
import { Outlet } from "react-router-dom";

function Dashboard(props) {

    const [loading, setLoading] = useState(true);
    const { loginState } = useAuth();

    useEffect(() => {
        
        setLoading(loginState.currentUser === null);
    }, [loginState]);

    const logOut = async () => {
        
        await signOut();

    }

    if (loading) {
        return (
            <Box sx={{ display: "flex", height: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
                <Typography
                    variant="h5"
                    noWrap

                    sx={{

                        display: { xs: "flex" },
                        flexGrow: { xs: 0 },
                        justifyContent: { xs: "center" },
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                >
                    Cargando
                </Typography>
            </Box>
        )
    }

    return (


        <Box sx={{display: "flex", flexDirection:"column", height:"100%"}}>
            <Scaffold currentUser={loginState.currentUser} onLogOut={logOut}>
                <Outlet />
            </Scaffold>

        </Box>
    );


}

export default Dashboard;