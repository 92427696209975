
import { AccessAlarm, Refresh,KeyboardArrowDown,Cached } from '@mui/icons-material';
import { Box,Button,Menu,MenuItem,ListItemIcon,ListItemText } from '@mui/material';
import { blueGrey,grey,purple,pink,green } from '@mui/material/colors';
import { ThemeProvider, useTheme, createTheme} from '@mui/material/styles';
import { useEffect, useState, useRef } from 'react';

const lightTheme = createTheme({
    palette: {
      primary: {
        main: grey[50],
      },
      background:{
        main: grey[50]
      }
    },
    text:{
        
    }
});

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary:{
        main: purple[500]
      },
      secondary: {
        main: green[500]
      },
    },
    components: {
        // Name of the component
        MuiButton: {
          styleOverrides: {
            // Name of the slot
            "root": {
                "&.Mui-disabled": {
                  "backgroundColor": blueGrey[400],
                  "color": blueGrey[50]
                }
            }
          },
        },
      },
});

const useInterval = (callback,delay) => {
    const savedCallback = useRef();
  
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }

      if(delay !== null ){
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
     
    }, [delay]);
};

function RefreshWidget(props) {

    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const options = [{label: "off"},{label: "30s",ms:30000},{label:"1m",ms:60000},{label:"5m",ms:300000}];

    const [selectedOption,setSelectedOption] = useState(0);

    useInterval(() => {
        console.log("corriendo onRefreshAction "+options[selectedOption].ms);
        
        props.onRefreshAction && props.onRefreshAction();
       
    },options[selectedOption].ms ? options[selectedOption].ms : null);


    useEffect(() => {
      if(!props.autoRefreshEnabled){
        setSelectedOption(0);
      }
     
    },[props.autoRefreshEnabled]);

    const handleClick = (evt) => {

        setAnchorEl(evt.currentTarget);
        console.log("click menu");
    };

    const onSelectedItem = (evt,index) => {

        setSelectedOption(index);
        console.log("selected item! "+index);
        setAnchorEl(null);
    };

    const handleClose = () => { 
        setAnchorEl(null);
        console.log("close menu");
    };

    const onRefreshBtn = evt => {
        console.log("Refresh Btn!");
        props.onRefreshAction && props.onRefreshAction();
    }

    return (

        <ThemeProvider theme={darkTheme} >

            <Box sx={{display: "flex", flexDirection:"row",...props.sx}}>
                <Button
                    disabled={props.disabled}
                    id="refreshBtn"
                    variant='contained'
                    onClick={onRefreshBtn}
                    sx={{textTransform:"none",bgcolor: blueGrey[700], color: grey[50],mr:"0.2em"}}
                    
                >
                    <Refresh />
                </Button>
                <Button
                    id="menuBtn"
                    disabled={props.disabled}
                    variant='contained'
                    aria-controls={open ? 'menuBtn' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{textTransform:"none",bgcolor: selectedOption === 0 ? blueGrey[500] : pink[700], color: grey[50], width:"7em"}}
                    endIcon={ <KeyboardArrowDown /> }
                    startIcon={ <Cached fontSize="small" /> }
                >
                   {options[selectedOption].label}
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    color="primary"
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    {
                        options.map( (o,ix) => {
                            return <MenuItem key={ix}
                                selected={ix === selectedOption}
                                onClick={evt => onSelectedItem(evt,ix)} >
                                <ListItemText >{o.label}</ListItemText>
                                
                            </MenuItem>
                            
                        })
                    }
                </Menu>
            </Box>
        </ThemeProvider>
        
    );


}

export default RefreshWidget;