

import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

import { auth } from '../firebase';
import firebase from "firebase/compat/app";
import { useEffect, useRef } from 'react';
import { useAuth } from "../auth-provider";
import { Box, CircularProgress,Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import PiacePiuLogo from "../piacepiu-logo";



function SignIn() {

    const theme = useTheme();
    const { loginState } = useAuth();
    const navigate = useNavigate();

    const elementRef = useRef(null);

    useEffect(() => {
        console.log("entra use effect de sign in",{loginState});

        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

        if(!loginState.loading) {

            if(loginState.currentUser){
               
                navigate("/dashboard");
            }else{

                ui.start("#firebaseui-auth-container", {
                    signInFlow: 'popup',
                    signInOptions: [
                        {
                            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                            requireDisplayName: false
                        },
                        {
                            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                            customParameters: {
                                // Forces account selection even when one account
                                // is available.
                                prompt: 'select_account'
                            },
                            scopes: [
                                "email",
                                "profile"
                            ]
                        }
                    ],
                    signInSuccessUrl: "/dashboard"
                });

            }

            
        }
         

    },[loginState]);

    return (
        <Box sx={{display:"flex" , height: "100%", flexDirection:"column", paddingTop:"2em", justifyContent:"flex-start", alignItems:"center"}}>
            <Box sx={{display:"flex" ,flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                <PiacePiuLogo sx={{ color: theme.palette.primary.main, fontSize: 150, display: { xs: 'flex' }, mr: 1 }} />
            </Box>
            <Typography
                variant="h3"
                noWrap
                
                sx={{
                    color: theme.palette.text.primary,
                    display: { xs: "flex" },
                    flexGrow: { xs: 0 },
                    justifyContent: { xs: "center" },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                }}
            >
                PiacePiu
            </Typography>

            <Typography
                variant="h5"
                noWrap
                
                sx={{
                   
                    display: { xs: "flex" },
                    flexGrow: { xs: 0 },
                    justifyContent: { xs: "center" },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                }}
            >
                Consola Web
            </Typography>


            <Box sx={{display: loginState.loading ? "flex" : "none"  , flexDirection:"column", paddingTop:"8em", justifyContent:"flex-start", alignItems:"center"}}>
                <CircularProgress />
                <Typography
                    variant="h7"
                    noWrap
                    
                    sx={{
                        mt: "3em",
                        display: { xs: "flex" },
                        flexGrow: { xs: 0 },
                        justifyContent: { xs: "center" },
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                >
                    cargando
                </Typography>
            </Box>
            
            <Typography
                variant="h7"
                noWrap
                
                sx={{
                    mt: "3em",
                    display: { xs: !loginState.loading&&!loginState.currentUser ? "flex" : "none"  },
                    flexGrow: { xs: 0 },
                    justifyContent: { xs: "center" },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                }}
            >
                Iniciar Sesión
            </Typography>
            <p></p>
            <div id="firebaseui-auth-container" ref={elementRef}>

            </div>
            
        </Box>

    );

}

export default SignIn;