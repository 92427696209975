import React from "react";
import {
    Routes,
    Route,
    Navigate
 } from "react-router-dom";
import ErrorPage from "./error-page";
import SignIn from "./routes/sign-in";
import Dashboard from "./routes/dashboard";
import { AuthProvider } from "./auth-provider";
import ProtectedRoute from "./protected-route";
import ControlTurnos from "./routes/control-turnos";



function AppRoutes() {



    return (
      <AuthProvider>
        <Routes>
          <Route path="/dashboard" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }>

            <Route path="control-turnos" element={
              <ProtectedRoute>
                <ControlTurnos />
              </ProtectedRoute>
            }></Route>

          </Route>
          <Route exact path="/sign-in" element={<SignIn />}></Route>
          <Route path="*" element={<Navigate to="/dashboard" replace/>}>
            
          </Route>
          
        </Routes>
      </AuthProvider>
       
    );
}

export default AppRoutes;