
import { useEffect, useState } from 'react';

import { Box, CircularProgress, Typography, Snackbar, Alert } from "@mui/material";
import { AccessAlarm, RecentActors } from "@mui/icons-material";
import { ThemeProvider, useTheme, createTheme } from '@mui/material/styles';
import { grey, purple } from '@mui/material/colors';
import CustomDatePicker from '../custom-date-picker';
import TurnosAPI from '../turnos-api';
import { db,auth } from '../firebase';
import { 
    query,
    onSnapshot,
    collection,
    deleteDoc,
    getDocs, 
    setDoc,
    doc, 
    serverTimestamp
} from "firebase/firestore";

import ItemSelector from '../item-selector';
import LoadingIndicator from '../loading-indicator';
import TurnosReportTable from '../turnos-report-table';
import PaymentMethodChart from '../payment-method-chart';
import RefreshWidget from '../refresh-widget';


const getFormattedString = value => {
    const userLocale =
        navigator.languages && navigator.languages.length
            ? navigator.languages[0]
            : navigator.language;

    let moneyString = new Intl.NumberFormat(userLocale);
    let result = moneyString.format(value);
    
    return result;
}

const turnosAPI = new TurnosAPI(auth);

const checkInvoice = async (invoiceId) => {

    await setDoc(doc(db, "checked_invoices", invoiceId),{
        timestamp: serverTimestamp()
    });
    
    return true;
}

const uncheckInvoice = async (invoiceId) => {
    await deleteDoc(doc(db, "checked_invoices", invoiceId));
    return true;
}

const getCheckedInvoices = async () => {
    const itemsColRef = collection(db, 'checked_invoices');
    return getDocs(itemsColRef)
}

const subscribeToCheckedInvoices = (onData,onError) => {

    const q = query(collection(db, "checked_invoices"));

    return onSnapshot(q, (querySnapshot) => {
        const checkedInvoices = {};

        querySnapshot.forEach((doc) => {
            checkedInvoices[doc.id] = doc.data();
        });
        console.log("Current checked invoices", {checkedInvoices});
        onData(checkedInvoices);

      },(error) => {
        console.debug("Error subscribe to checked invoices",{error});
        onError(error);

      });
}

function ControlTurnos() {

    const theme = useTheme();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [invoices, setInvoices] = useState([]);
    const [turnosList, setTurnosList] = useState(null);
    const [turnos, setTurnos] = useState(null);
    const [totalTurno, setTotalTurno] = useState(null);
    const [querying, setQuerying] = useState(false);
    const [paymentMethodProportion, setPaymentMethodProportion] = useState(null);
    const [message, setMessage] = useState(null);
    const [checkedInvoices,setCheckedInvoices] = useState(null);
    const [autoRefreshEnabled,setAutoRefreshEnabled] = useState(true);


    const getInvoices = async (date) => {
        
        setQuerying(true);

        try {

            let response = await turnosAPI.getInvoices(date);

            setQuerying(false);
    
            setTurnosList(response.shifts);
    
            let array = [];
            response.shifts.forEach(el => {
                array.push(el.seller.name + " | " + (new Date(el.start).toLocaleString()) + " - " + (new Date(el.end).toLocaleString()));
            });
    
            setTurnos(array);

        }catch(err) {
            setQuerying(false);
            setMessage({
                type:"error",
                text:err.toString()
            });
            
        }

        return;
    }

    useEffect(() => {
        setMessage({
            type:"info",
            text:"Cargando..."
        });
        let listener = subscribeToCheckedInvoices((checkedInvoices) => {
            setCheckedInvoices(checkedInvoices);
            setMessage(null);
        },(error) => {
            console.error("Error on useEffect control-turnos",{error});
            setMessage({
                type:"error",
                text:"Error al cargar facturas marcadas desde la base de datos Firestore: "+error.toString()
            });
            turnosAPI.timeout(2000).then(() => {
                setMessage(null);
            });
           
        });

        return listener;


    }, []);


    useEffect(() => {

        turnosAPI.calculateTotalsTurno(invoices).then( turnoTotals => {

            setTotalTurno(turnoTotals.totalTurno);  

            setPaymentMethodProportion({
                total: turnoTotals.totalTurno,
                items: turnoTotals.totalsBySaleCondition
            }); 

        }).catch(err => {
            console.error(err);
        });

    },[invoices]);

    const queryDateChanged = val => {
       
        setSelectedDate(val);
        let d = new Date(val.getTime());
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);

        let dAct = new Date();
        dAct.setHours(5);
        dAct.setMinutes(0);
        dAct.setSeconds(0);
        dAct.setMilliseconds(0);

        console.debug("querydateChanged: preguntando por fechas ",{val,dAct,comp: ( val.getTime() <= dAct.getTime() ) });

        if(val.getTime() <= dAct.getTime()){
            
            dAct.setDate(dAct.getDate() - 1 );
            
            if(val.toLocaleDateString() === dAct.toLocaleDateString()){
                console.debug("querydateChanged: fecha/hora de consulta menor o igual a fecha actual a las 5 am",{val,dAct});
                setAutoRefreshEnabled(true);
            }else{
                setAutoRefreshEnabled(false);
            }       
            
        }else {
            console.debug("querydateChanged: va por el else ",{val,dAct});

            if(val.toLocaleDateString() === dAct.toLocaleDateString()) {
                console.debug("querydateChanged: fecha de consulta igual a fecha actual",{val,dAct});
                setAutoRefreshEnabled(true);
            }else{
                setAutoRefreshEnabled(false);
            }
            
        }

        //console.log("selected Date ",{date: d.toLocaleString()});
       

        getInvoices(val);
    }

    const handleSnackClose = () => {
        setMessage(null);
    }

    const setItemChecked = async item => {
        console.log("llego a processed! ",{item});
        try{
            

            if(checkedInvoices[item.id]){

                setMessage({
                    type:"info",
                    text:"Desmarcando la factura "+item.id+"..."
                });
                await uncheckInvoice(item.id);
                setMessage({
                    type:"success",
                    text:"Desmarcaste la factura "+item.id+"."
                });

                await turnosAPI.timeout(2000);
                setMessage(null);

            }else{
                setMessage({
                    type:"info",
                    text:"Marcando la factura "+item.id+"..."
                });
                await checkInvoice(item.id);
                setMessage({
                    type:"success",
                    text:"Marcaste la factura "+item.id+"."
                });
                await turnosAPI.timeout(2000);
                setMessage(null);
            }
            
            

        }catch(err){
            setMessage({
                type:"error",
                text:err.toString()
            });
            await turnosAPI.timeout(2000);
            setMessage(null);
        }
        
    }

    const onTurnoSelected = turno => {
        console.log("Seleccionó un turno! "+turno);

        if(turnosList[turno]){
            setInvoices(turnosList[turno].invoices);
        }else{
            setInvoices([]);
        }
        

    }

    const refreshData = async () => {
        await getInvoices(selectedDate);
    }


    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', flexDirection: "column",  overflowY:"auto", p: 1, width: "100%" }}>
                <Box sx={{ color: grey[100], display: 'flex', mr: 1, alignItems: "center" }}>
                    <RecentActors sx={{ color: grey[700], fontSize: 30, display: { xs: 'flex' }, mr: 1 }} />
                    <Typography
                        variant="h7"
                        noWrap
                        sx={{
                            mr: 1,
                            display: { xs: "flex" },
                            flexGrow: { xs: 0 },
                            justifyContent: { xs: "center" },
                            fontFamily: theme.text.section.title.fontFamily,
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: theme.palette.text.section.title.primary.main,
                            textDecoration: 'none',
                        }}
                    >
                        Control Turnos
                    </Typography>
                </Box>

                <Box sx={{ display: "flex", width: "100%", flexDirection: { xs: "column", md: "row" }, justifyContent: "center", alignItems: "center", p: 1 }}>
                    <Box sx={{ display: "flex", flexDirection: { xs: "row"}, justifyContent: "center", alignItems: "center", mt: "1em", mb: "1em", p: 1 }}>
                        <CustomDatePicker disabled={ querying } label="Fecha de consulta" date={selectedDate} onChange={queryDateChanged} sx={{ minWidth: 200 }} />
                    </Box>
                    <Box sx={{ display: "flex",flexDirection: { xs: "column", sm: "row" }, justifyContent: "flex-start", alignItems: "center" }}>
                        <Box sx={{ display: "flex", flexGrow: "1", width: "100%", flexDirection: { xs: "column", md: "row" }, alignItems: "center", justifyContent: "flex-start", p: 1 }}>
                            <ItemSelector label="Turnos" disabled={ querying } items={turnos} sx={{ width: { xs: 300, sm: 320, md: 400, lg: 550 }}}  onSelected={onTurnoSelected} />
                        </Box>

                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", mt: "1em", mb: "1em", p: 1 }}>
                            <RefreshWidget label="Refresh" disabled={ querying } autoRefreshEnabled={autoRefreshEnabled} onRefreshAction={refreshData} />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", width: "100%",flexGrow: "1", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                    <Box sx={{ display: querying ? "flex" : "none", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "flex-start" }}>
                        <LoadingIndicator text="Consultando facturas" sx={{ justifyContent: "flex-start" }} progressColor={purple[500]}></LoadingIndicator>
                    </Box>
                    <Box sx={{ display:"flex",  width: "100%", flexDirection: "column", height:"100%", alignItems: "flex-start", justifyContent: "flex-start"}}>
                        
                        <TurnosReportTable sx={{ border: "1px solid #e1e1e1", pb:"0.5em", borderRadius: "0.5em",flexGrow:"1", height:{xs:"20em", md:"auto" }}} invoices={invoices} onItemChecked={setItemChecked} checkedInvoices={checkedInvoices} />
                        
                        <Box sx={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", p: 1, mt: 1, border: "1px solid #e1e1e1", borderRadius: "0.5em" }}>
                            <Typography
                                variant="h7"
                                noWrap
                                sx={{
                                    mr: 1,
                                    display: { xs: "flex" },
                                    flexGrow: { xs: 0 },
                                    justifyContent: { xs: "center" },
                                    fontFamily: theme.text.section.title.fontFamily,
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: theme.palette.text.section.title.primary.main,
                                    textDecoration: 'none',
                                }}
                            >
                                Total Turno: {"$" + getFormattedString(totalTurno) || "--"}
                               </Typography>
                        </Box>
                        <PaymentMethodChart data={paymentMethodProportion} sx={{ mt: 1 }} />
                    </Box>

                    <Box sx={{ display: !querying && invoices.length === 0 ? "flex": "none", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", p: 1 }}>
                        <Typography
                            variant="p"
                            noWrap
                            sx={{
                                mt:5,
                                display: { xs: "flex" },
                                flexGrow: { xs: 0 },
                                justifyContent: { xs: "center" },
                                fontWeight: 500,
                                color: theme.palette.text.section.title.secondary.main,
                                textDecoration: 'none',
                            }}
                        >
                            Seleccioná una fecha para consultar
                        </Typography>
                    </Box>

                </Box>
                <Snackbar anchorOrigin={{ vertical:"bottom", horizontal:"center" }} open={message !== null} onClose={handleSnackClose}  >
                    <Alert severity={message !== null ? message.type : "info"} sx={{ width: '100%' }}>
                        { message !== null ? message.text : ""}
                    </Alert>
                </Snackbar>
            </Box>
        </ThemeProvider>



    );
}

export default ControlTurnos;