


import { CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useAuth } from './auth-provider';
  
const ProtectedRoute = ({ children }) => {
    const { loginState } = useAuth();

    if(loginState.loading) {
      return <div />
    }

    if (!loginState.currentUser) {
      return <Navigate to="/sign-in" replace />;
    }
  
    return children;
};

export default ProtectedRoute;