import * as React from "react";
import { SvgIcon } from "@mui/material";

export default function PiacePiuLogo(props) {
  return (
    <SvgIcon
      {...props}
      version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="716.000000pt" height="707.000000pt" viewBox="0 0 716.000000 707.000000"
      preserveAspectRatio="xMidYMid meet"
    >
    <g transform="translate(0.000000,707.000000) scale(0.100000,-0.100000)"
stroke="none">
<path d="M3495 6333 c-146 -19 -270 -55 -386 -113 -166 -83 -298 -202 -375
-336 l-40 -69 -102 -12 c-282 -33 -544 -171 -688 -363 -40 -54 -82 -143 -113
-237 l-28 -86 -79 -32 c-177 -71 -276 -136 -375 -243 -142 -154 -244 -391
-264 -614 -6 -62 -11 -79 -25 -83 -33 -10 -134 -81 -179 -125 -61 -59 -136
-161 -171 -231 -75 -148 -134 -343 -149 -499 -24 -240 80 -556 248 -751 44
-51 157 -145 201 -167 63 -33 72 -48 80 -136 15 -163 80 -341 176 -483 59 -86
167 -193 239 -236 35 -20 215 -107 262 -126 28 -11 36 -25 69 -109 80 -208
165 -317 339 -437 125 -86 344 -162 517 -180 l58 -6 30 -57 c94 -175 294 -327
535 -405 182 -59 439 -61 630 -5 243 71 344 126 471 252 50 50 106 118 125
151 l35 60 70 8 c99 12 285 62 368 101 110 51 209 120 284 201 90 96 119 146
215 365 l24 55 79 24 c43 13 115 42 159 64 145 74 282 237 360 429 44 108 85
264 92 350 5 52 10 69 23 73 33 10 141 85 192 133 247 229 368 652 289 1010
-61 281 -223 521 -429 639 l-67 39 -7 74 c-18 182 -96 395 -191 522 -124 164
-228 242 -423 317 l-96 36 -42 111 c-24 61 -60 138 -80 171 -140 227 -441 399
-749 428 l-76 7 -32 57 c-109 195 -331 358 -581 425 -144 39 -308 54 -423 39z
m232 -314 c170 -23 309 -87 414 -190 65 -64 149 -194 149 -231 0 -16 12 -18
118 -18 128 0 267 -28 368 -74 156 -71 284 -191 358 -336 45 -87 86 -196 86
-229 0 -9 5 -33 11 -53 7 -28 15 -38 31 -38 30 0 135 -28 198 -53 176 -70 327
-203 404 -355 69 -136 111 -325 103 -459 -3 -53 0 -73 8 -73 7 0 44 -16 83
-36 109 -54 209 -159 266 -276 68 -143 80 -199 80 -368 0 -109 -4 -161 -18
-210 -41 -149 -130 -293 -229 -372 -50 -39 -161 -98 -186 -98 -8 0 -10 -21 -5
-77 3 -48 0 -125 -10 -199 -26 -210 -93 -352 -223 -481 -60 -59 -93 -82 -186
-127 -71 -35 -140 -60 -189 -70 -42 -9 -79 -16 -81 -16 -2 0 -10 -26 -17 -58
-24 -108 -84 -236 -161 -343 -152 -208 -408 -315 -706 -293 -79 6 -93 4 -93
-8 0 -9 -16 -46 -35 -84 -115 -227 -384 -365 -680 -351 -60 3 -141 14 -180 25
-218 60 -397 212 -455 387 l-12 37 -57 -7 c-66 -8 -225 15 -321 47 -285 93
-486 315 -547 606 l-17 81 -50 6 c-68 9 -175 47 -251 89 -185 102 -309 248
-377 446 -33 96 -59 268 -52 338 5 48 3 62 -7 62 -32 0 -163 77 -212 125 -50
48 -137 164 -137 182 0 5 -9 25 -20 45 -63 118 -82 381 -40 543 12 44 25 84
29 90 4 5 14 26 21 45 48 123 194 266 321 314 l47 18 0 136 c-1 151 16 253 57
354 90 221 301 394 568 464 58 16 68 22 73 44 40 197 119 347 244 463 149 138
389 213 635 200 l80 -5 34 76 c39 88 123 187 212 247 32 21 62 39 66 39 5 0
24 8 43 19 52 27 105 43 195 57 103 16 156 16 252 3z"/>
<path d="M3555 5984 c-190 -26 -316 -80 -425 -181 -72 -66 -128 -151 -150
-229 l-12 -40 -161 0 c-189 0 -274 -17 -417 -86 -70 -34 -100 -56 -170 -128
-47 -47 -94 -104 -105 -126 -11 -21 -22 -41 -26 -44 -14 -12 -71 -189 -77
-240 l-7 -55 -60 -13 c-239 -53 -450 -201 -544 -381 -74 -143 -99 -301 -81
-515 l5 -58 -55 -17 c-229 -72 -399 -338 -400 -628 0 -300 163 -560 400 -634
44 -14 54 -21 51 -36 -1 -10 -3 -65 -4 -123 -1 -183 50 -336 165 -492 107
-146 319 -270 501 -293 l55 -7 7 -56 c20 -160 110 -338 227 -449 123 -116 257
-178 458 -213 92 -16 113 -17 173 -6 69 13 69 13 78 -11 5 -13 9 -29 9 -34 0
-5 15 -40 34 -77 34 -68 139 -185 177 -197 10 -4 19 -10 19 -14 0 -13 138 -73
214 -93 130 -34 317 -24 452 25 173 63 321 205 366 352 l15 50 144 -3 c161 -4
255 10 358 52 168 68 319 221 396 401 26 60 65 187 65 212 0 17 8 23 43 28
200 33 343 106 460 235 146 162 200 365 184 688 -2 32 1 34 51 52 83 30 142
68 208 136 136 140 201 344 182 570 -21 259 -175 478 -390 554 -50 18 -53 20
-51 52 10 182 -5 293 -60 433 -17 44 -34 82 -37 85 -3 3 -20 28 -39 57 -97
153 -309 285 -507 314 -41 6 -74 14 -74 18 0 3 -9 49 -20 101 -14 64 -37 129
-71 199 -112 228 -323 374 -598 412 -42 6 -116 9 -165 7 l-89 -3 -12 40 c-56
187 -246 344 -468 389 -85 17 -180 25 -222 20z m1079 -677 c9 -15 6 -22 -17
-42 -29 -26 -38 -28 -196 -45 -57 -6 -138 -20 -180 -31 -82 -21 -181 -65 -181
-80 0 -5 9 -20 21 -32 39 -41 62 -100 68 -171 l6 -70 51 -11 c65 -15 113 -45
164 -102 60 -68 80 -121 80 -210 0 -79 -14 -126 -55 -179 -28 -37 -29 -35 43
-59 65 -21 159 -83 190 -125 82 -110 67 -249 -37 -347 -150 -140 -124 -90
-239 -463 -57 -184 -124 -398 -148 -475 -24 -77 -75 -246 -114 -375 -39 -129
-77 -253 -85 -275 -7 -22 -16 -55 -20 -72 -4 -18 -11 -35 -16 -38 -21 -14 -7
-41 32 -59 149 -72 101 -203 -93 -256 -56 -15 -58 -24 -32 -124 29 -112 29
-112 161 -120 175 -11 348 -61 403 -116 25 -25 25 -68 2 -96 -89 -110 -469
-194 -876 -194 -478 1 -765 112 -619 240 67 59 223 113 418 145 55 9 103 20
106 23 13 14 29 103 29 160 l0 59 -57 12 c-75 16 -161 55 -195 88 -22 21 -28
36 -28 68 0 53 20 80 83 111 29 15 53 27 54 28 12 10 -370 1035 -537 1446 -35
85 -50 136 -48 160 3 32 0 36 -43 57 -116 58 -169 136 -169 250 0 62 4 75 30
111 30 42 89 82 152 101 l36 12 -25 55 c-69 151 -22 319 133 470 134 130 347
214 545 214 l97 0 11 43 c14 59 79 130 146 161 45 22 68 26 139 26 54 0 100
-6 125 -16 37 -15 40 -14 77 11 55 37 168 89 239 110 153 45 348 56 369 22z"/>
<path d="M3795 5103 c-36 -7 -100 -38 -118 -56 -30 -30 -57 -81 -57 -108 0
-20 10 -33 39 -52 43 -29 104 -87 119 -113 8 -15 15 -14 76 10 37 15 95 32
129 38 34 6 65 16 69 22 14 20 8 91 -11 131 -26 56 -68 92 -134 115 -56 20
-73 22 -112 13z"/>
<path d="M3335 4865 c-5 -1 -37 -9 -70 -16 -127 -27 -272 -116 -344 -211 -70
-94 -96 -161 -96 -254 0 -73 3 -85 29 -123 16 -23 47 -54 68 -69 20 -15 38
-30 38 -34 0 -4 -21 -9 -47 -12 -88 -11 -176 -36 -208 -61 -42 -32 -69 -88
-61 -129 12 -64 147 -170 263 -206 161 -49 220 -54 693 -55 495 0 572 7 765
66 114 35 164 67 197 124 28 49 30 143 5 191 -41 79 -149 145 -268 166 -35 5
-70 12 -78 14 -8 3 6 25 42 63 128 139 140 267 35 368 -92 88 -249 92 -455 9
-50 -20 -95 -36 -101 -36 -6 0 -20 21 -33 46 -30 62 -81 112 -146 140 -43 19
-70 24 -136 23 -45 0 -86 -2 -92 -4z m7 -282 c27 -24 22 -52 -13 -71 -55 -30
-98 -73 -118 -118 -11 -24 -24 -44 -30 -44 -19 0 -30 36 -22 71 10 44 56 134
81 159 24 24 77 26 102 3z m786 -429 c52 -40 92 -110 92 -161 0 -37 -6 -50
-41 -88 -60 -65 -111 -54 -59 12 34 43 26 86 -21 130 -85 77 -87 80 -69 114
15 27 57 24 98 -7z m-908 -64 c12 -23 -4 -178 -21 -195 -15 -15 -39 17 -54 76
-18 66 -19 111 -3 127 19 19 66 15 78 -8z"/>
<path d="M4220 3639 c-239 -36 -808 -57 -1099 -40 -121 8 -207 9 -213 3 -5 -5
16 -75 53 -173 34 -90 74 -200 89 -244 16 -44 54 -152 85 -240 31 -88 80 -230
110 -315 159 -461 185 -533 190 -539 4 -3 78 -8 165 -9 170 -4 257 7 284 37
15 17 42 99 126 391 54 187 200 672 301 1000 16 53 29 106 29 118 0 25 -19 27
-120 11z m-1037 -183 c26 -30 209 -584 268 -811 4 -16 21 -84 39 -150 42 -160
60 -254 49 -261 -5 -3 -11 1 -13 8 -43 151 -191 585 -261 768 -49 128 -155
426 -155 436 0 13 26 33 43 34 5 0 18 -11 30 -24z m431 8 c33 -13 38 -48 46
-338 5 -160 12 -382 16 -493 5 -145 4 -203 -4 -203 -13 0 -16 26 -37 265 -20
228 -52 540 -65 627 -19 131 -10 163 44 142z m457 -14 c26 -14 25 -29 -16
-182 -19 -73 -46 -182 -60 -243 -84 -372 -91 -395 -106 -395 -12 0 -11 35 1
98 5 28 21 126 35 219 27 176 49 315 67 421 7 44 17 67 31 77 25 18 23 18 48
5z"/>
<path d="M3408 2017 c-121 -38 -97 -122 46 -166 179 -55 456 -21 511 63 21 32
13 59 -30 92 l-34 26 -120 -11 c-66 -6 -125 -9 -133 -7 -37 12 -206 14 -240 3z"/>
<path d="M3590 1742 c0 -38 -38 -160 -69 -224 -39 -79 -84 -125 -131 -134
l-35 -7 20 24 c11 13 31 37 45 52 l25 28 -55 -6 c-72 -9 -223 -48 -280 -73
-60 -26 -98 -67 -84 -90 21 -33 89 -61 202 -82 105 -20 139 -21 381 -17 290 5
428 22 579 73 139 46 174 94 103 141 -40 27 -73 37 -199 58 -122 21 -133 19
-98 -21 43 -52 34 -68 -19 -36 -79 49 -146 149 -175 261 l-16 66 -97 3 c-89 2
-97 1 -97 -16z"/>
</g>
    </SvgIcon>
  );
}